<template>
	<app-layout v-loading="pageLoading" size="none" class="staff moneyBox" :needHeader="false">
		<div style="padding: 15px 30px">
			<el-tabs v-model="activeName" @tab-click="currentChange(1)">
				<el-tab-pane v-for="(item, i) in items" :key="i" :label="item.label" :name="item.id"> </el-tab-pane>
			</el-tabs>
			<el-form :model="searchForm" ref="searchForm" inline label-width="50px" v-if="activeName == '1'">
				<el-form-item label="月份" style="margin-bottom: 15px">
					<el-date-picker
						v-model="searchForm.month"
						size="small"
						type="month"
						value-format="yyyy-MM"
						placeholder="选择查询月份"
					></el-date-picker>
				</el-form-item>
				<el-button type="primary" style="margin-left: 10px; transform: translateY(5px)" @click="getList" icon="el-icon-search" size="small"
					>查询</el-button
				>
			</el-form>
			<el-table :data="data" stripe v-show="activeName == '1'" class="tableBackground">
				<el-table-column label="序号" type="index" align="center"></el-table-column>
				<el-table-column prop="month" align="center" label="月份"></el-table-column>
				<el-table-column v-for="col in columns" :prop="col.id" align="center" :key="col.id" :label="col.label" :width="col.width">
				</el-table-column>
				<el-table-column prop="payableValue" align="center" label="应发工资"></el-table-column>
				<el-table-column prop="paidValue" align="center" label="实发工资"></el-table-column>
				<el-table-column prop="grantDateTime" align="center" label="发放时间" width="165"></el-table-column>

				<el-table-column label="操作" align="center">
					<template slot-scope="{ row }">
						<el-button type="text" @click="detail(row, true)">详情</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-table :data="data" stripe v-show="activeName == '2'" class="tableBackground">
				<el-table-column label="序号" type="index" align="center" width="60"></el-table-column>
				<el-table-column v-for="col in column1s" :prop="col.id" align="center" :key="col.id" :label="col.label" :width="col.width">
				</el-table-column>
			</el-table>
			<el-pagination
				@size-change="sizeChange"
				@current-change="currentChange"
				:current-page="table.pageNo"
				:page-sizes="[20, 40, 50, 80, 100]"
				:page-size="table.pageSize"
				style="float: right; margin-top: 20px"
				layout="total, sizes, prev, pager, next, jumper"
				:total="table.totalNum"
			>
			</el-pagination>
		</div>
	</app-layout>
</template>

<script>
import { payrollConfigDetail, payrollStaffList, payrollBonusStaffList } from '../../api/remuneration';
export default {
	data() {
		return {
			activeName: '1',
			pageLoading: false,
			searchForm: {
				month: ''
			},
			items: [
				{ id: '1', label: '工资条' },
				{ id: '2', label: '年终奖' }
			],
			columns: [
				{ label: '姓名', id: 'staffName' },
				{ label: '工号', id: 'jobNumber' },
				{ label: '部门', id: 'orgName' },
				{ label: '职位', id: 'jobName' },
				{ label: '实际出勤天数', id: 'actualAttendance', width: '120px' },
				// {label:"发放状态",id:"grantTypeName"},
				{ label: '应发工资', id: 'payableValue' },
				{ label: '实发工资', id: 'paidValue' },
				{ label: '发放时间', id: 'grantDateTime', width: '120px' }
			],
			column1s: [
				{ label: '年份', id: 'year' },
				{ label: '姓名', id: 'staffName' },
				{ label: '工号', id: 'jobNumber' },
				{ label: '职位', id: 'jobName' },
				{ label: '年终奖金', id: 'bonusAmount' }
			],
			data: [],
			table: {
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			}
		};
	},
	async mounted() {
		// this.getMonthString(true);
		const boolean = await this.getConfig();

		if (boolean) {
			this.getList();
		}
	},
	methods: {
		detail(row, isinfo) {
			sessionStorage.setItem(
				'staffremunerationInfo',
				JSON.stringify({ staffId: row.staffId, month: row.month, grantDateTime: row.grantDateTime, isinfo })
			);
			this.$router.push({
				name: 'staffSalaryDetails',
				params: { staffId: row.staffId, month: row.month, grantDateTime: row.grantDateTime, isinfo }
			});
		},
		getList() {
			if (this.activeName == '1') {
				payrollStaffList({ month: this.searchForm.month }).then((res) => {
					if (res._responseStatusCode == 0) {
						res.list.forEach((it) => {
							it.payrollList.forEach((it1) => {
								this.columns.forEach((it2) => {
									if (`Config${it1.id}` == it2.id) {
										it[it2.id] = it1.money;
									}
								});
							});
						});
						this.data = res.list;
						this.table.totalNum = res.totalNum;
					}
				});
			} else {
				payrollBonusStaffList({}).then((res) => {
					if (res._responseStatusCode == 0) {
						this.data = res.list;
						this.table.totalNum = res.totalNum;
					}
				});
			}
		},
		getMonthString(now) {
			const stringArr = new Date().toLocaleDateString().split('/');

			let year = Number(stringArr[0]);

			let month = Number(stringArr[1]);

			if (now) {
				month--;
			} else {
				month -= 2;
			}
			if (month == 0) {
				month = '12';
				year--;
			} else if (month < 0) {
				year--;
				month = 12 + month;
			} else if (month > 0 && month < 10) {
				month = `0${month}`;
			}
			this.searchForm.month = `${year}-${month}`;
		},
		async getConfig() {
			const objArr = await payrollConfigDetail({});

			this.columns = [
				{ label: '姓名', id: 'staffName' },
				{ label: '工号', id: 'jobNumber' },
				{ label: '部门', id: 'orgName' },
				{ label: '职位', id: 'officeNmae' },
				{ label: '实际出勤天数', id: 'actualAttendance', width: '120px' }
				// {label:"发放状态",id:"grantTypeName"},
			];
			objArr.forEach((it) => {
				this.columns.push({ id: `Config${it.id}`, label: it.typeName });
			});
			return objArr;
		},
		sizeChange(val) {
			this.table.pageSize = val;
			this.getList();
		},
		currentChange(val) {
			this.table.pageNo = val;
			this.getList();
		}
	}
};
</script>

<style lang="scss">
.moneyBox {
	label {
		font-weight: normal;
	}
	//   .el-tabs__item {
	//     font: bold 16px Microsoft YaHei;
	//   }
}
</style>
